import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useQuery } from '@apollo/client'

import { STOCK_STATUS_QUERY } from '../fragments'
import Layout from '../layout'
import { BlockDivider, DropShadow, ItemContent, LoadingBlock, PurchaseButton, UIView } from '../components/atoms'
import { Carousel, ItemHeader, ItemPrice, MerchSizes, ShopItem, SidebarHeader } from '../components/molecules'
import { AbstractItem } from '../components/templates'
import {
  pluckMetaValueFromShopifyProduct,
  transformStockStatusResult,
  getSoldOutFromStockStatusResult,
  getMinVariantPriceFromStockStatusResult,
  getShopifyGatsbyImageData
} from '../utils'
import { arrayOf } from 'prop-types'
import { ShopItemType } from '../types'
import styled from 'styled-components'
import RelatedItemsSection from '../components/organisms/RelatedItemsSection'

const Apparel = ({ data, location }) => {
  const [selectedVariantId, setSelectedVariantId] = useState(null)
  const merch = data.product || {}
  const relatedMerch = data.relatedMerch.nodes
  const title = pluckMetaValueFromShopifyProduct(merch, 'title')
  const subtitle = pluckMetaValueFromShopifyProduct(merch, 'subtitle')

  const stockStatusQueryResult = useQuery(STOCK_STATUS_QUERY, {
    variables: { handle: merch.handle }
  })

  const variants = transformStockStatusResult(stockStatusQueryResult)

  if (stockStatusQueryResult.error)
    console.log('Error while fetching Shopify stock status: ', stockStatusQueryResult.error)

  return (
    <Layout pageName={merch.title} location={location}>
      <UIView
        desktop={() => (
          <AbstractItem
            left={<Carousel images={merch.images} />}
            middle={
              <>
                <ItemHeader
                  title={title}
                  subtitle={subtitle}
                  description={pluckMetaValueFromShopifyProduct(merch, 'description')}
                  itemType={merch.productType.toLowerCase()}
                />
                <ItemContent content={merch.descriptionHtml} />
              </>
            }
            right={
              <>
                <div>
                  <SidebarHeader>Sizes</SidebarHeader>
                  <BlockDivider />
                </div>
                <div>
                  {stockStatusQueryResult.loading || (stockStatusQueryResult.error && <LoadingBlock />)}
                  {stockStatusQueryResult.data && (
                    <>
                      <MerchSizes
                        variants={transformStockStatusResult(stockStatusQueryResult)}
                        selectedVariantId={selectedVariantId}
                        setSelectedVariantId={setSelectedVariantId}
                      />
                      <BlockDivider />
                    </>
                  )}
                </div>
                {stockStatusQueryResult.data && (
                  <>
                    <ItemPrice
                      price={
                        selectedVariantId
                          ? variants.find((variant) => variant.shopifyId === selectedVariantId)?.price
                          : getMinVariantPriceFromStockStatusResult(stockStatusQueryResult)
                      }
                      compareAtPrice={
                        variants.find((variant) => variant.shopifyId === selectedVariantId)?.compareAtPrice
                      }
                    />
                  </>
                )}
                {stockStatusQueryResult.data && (
                  <>
                    <PurchaseButton
                      variantId={selectedVariantId}
                      itemType={merch.productType}
                      soldOut={getSoldOutFromStockStatusResult(stockStatusQueryResult)}
                    />
                  </>
                )}
              </>
            }
          />
        )}
        mobile={() => (
          <AbstractItem
            left={<Carousel images={merch.images} />}
            middle={
              <>
                <BlockDivider itemType={merch.productType.toLowerCase()} />
                <ItemHeader
                  title={title}
                  subtitle={subtitle}
                  description={pluckMetaValueFromShopifyProduct(merch, 'description')}
                  divider={false}
                />
                {stockStatusQueryResult.loading || (stockStatusQueryResult.error && <LoadingBlock />)}
                {stockStatusQueryResult.data && (
                  <>
                    <ItemPrice
                      price={
                        selectedVariantId
                          ? variants.find((variant) => variant.shopifyId === selectedVariantId)?.price
                          : getMinVariantPriceFromStockStatusResult(stockStatusQueryResult)
                      }
                      compareAtPrice={
                        variants.find((variant) => variant.shopifyId === selectedVariantId)?.compareAtPrice
                      }
                    />
                    <MerchSizes
                      variants={transformStockStatusResult(stockStatusQueryResult)}
                      selectedVariantId={selectedVariantId}
                      setSelectedVariantId={setSelectedVariantId}
                    />
                    <PurchaseButton
                      variantId={selectedVariantId}
                      itemType={merch.productType}
                      soldOut={getSoldOutFromStockStatusResult(stockStatusQueryResult)}
                    />
                  </>
                )}
              </>
            }
            right={<ItemContent content={merch.descriptionHtml} />}
          />
        )}
      />
      <RelatedItemsSection items={relatedMerch} />
    </Layout>
  )
}

export const query = graphql`
  query ApparelQuery($pagePath: String!) {
    product: shopifyProduct(handle: { eq: $pagePath }) {
      handle
      title
      productType
      descriptionHtml
      metafields {
        key
        namespace
        value
      }
      images {
        gatsbyImageData(layout: CONSTRAINED, height: 840)
      }
    }
    relatedMerch: allShopifyProduct(
      filter: { productType: { eq: "Apparel" } }
      sort: { fields: createdAt, order: DESC }
      limit: 6
    ) {
      nodes {
        handle
        productType
        title
        createdAt
        tags
        priceRangeV2 {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        variants {
          shopifyId
          price
          title
          compareAtPrice
        }
        metafields {
          namespace
          key
          value
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
      }
    }
  }
`

export default Apparel
